import { useBreakpoints } from '@vueuse/core';

export const GP_BREAKPOINTS = {
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200,
  '2xl': 1440,
};

export const useAppBreakpoints = () => useBreakpoints(GP_BREAKPOINTS);
